@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '../node_modules/ngx-toastr/toastr';
@import 'material-symbols';

:root {
    --ista-dark-rey: #f4f5f9;
    --ista-ocean-blue: #00003c;
    --ista-blue: #003372;
    --ista-sky-blue: #0071ff;
    --ista-light-green: #c6f7d6;
    --ista-lime-green: #1beb83;
    --ista-dark-green: #119553;
    --ista-orange: #fef7e7;
    --ista-dark-orange: #f2ad00;
    --ista-light-red: #fdece7;
    --ista-red: #e73c06;

    .ista-dark-grey {
        color: rgb(95, 99, 104);
    }

    .ista-dark-blue {
        color: rgb(0, 0, 60);
    }

    .ista-blue {
        color: rgb(0, 51, 114);
    }

    .ista-light-blue {
        color: rgb(0, 113, 255);
    }

    .ista-green {
        color: rgb(27, 235, 131);
    }

    .ista-red {
        color: #e73c06;
    }
}

.body {
    font-family: 'TheSansE5-SemiLight', sans-serif;
}

.showScrollWhenNeeded {
    position: relative;
    display: block;
    overflow: auto;
    height: 100%;
    width: 100%;
}

.toShowInSmallWindow {
    visibility: hidden;
}

@media screen and (max-width: 1150px) {
    .toShowInSmallWindow {
        visibility: visible;
    }

    h1 {
        font-size: 2rem;
    }
}

.gro .mark,
mark {
    background-color: yellow !important;
    padding: 0 !important;
}

/* .tooltip.show {
    opacity: 1 !important;
}

.tooltipClass .tooltip-inner {
    border: 1px solid #9da9c4;
    border-radius: 5px;
    background: #fff;
    color: #0a2864;
    font-size: 12px;
    z-index: 1000;
    padding: 4px;
    max-width: 400px;
} */

/* .customClass .tooltip-inner {
    border: 1px solid #9DA9C4;
    border-radius: 5px;
    background: #fff;
    color: #0a2864;
    font-size: 12px;
    padding: 10px 10px 10px 10px;
    max-width: 400px;
} */

.tooltip.show {
    opacity: 1 !important;
}

.tooltipClass .tooltip-inner {
    border-radius: 5px;
    background: #1e293b;
    color: #f4f7f9;
    font-size: 12px;
    z-index: 10000;
    padding: 4px;
    max-width: 400px;
    font-family: 'TheSansE5-Plain';
}

.customClass .tooltip-inner {
    border-radius: 5px;
    background: #1e293b;
    color: #f4f7f9;
    font-size: 12px;
    z-index: 10000;
    padding: 10px 10px 10px 10px;
    max-width: 400px;
    font-family: 'TheSansE5-Plain';
}

.tooltipClass .tooltip-inner {
    border-radius: 5px;
    background: #1e293b;
    color: #f4f7f9;
    font-size: 12px;
    z-index: 10000;
    padding: 4px;
    max-width: 400px;
    font-family: 'TheSansE5-Plain';
}

.customClass .tooltip-inner {
    border-radius: 5px;
    background: #1e293b;
    color: #f4f7f9;
    font-size: 12px;
    z-index: 10000;
    padding: 10px 10px 10px 10px;
    max-width: 400px;
    font-family: 'TheSansE5-Plain';
}

.bs-tooltip-auto.arrow::before,
.bs-tooltip-top .arrow::before,
.bs-tooltip-bottom .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: transparent !important;
}

.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: transparent !important;
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    margin-left: 0px;
    border-width: 5px 5px 0;
    border-top-color: transparent !important;
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: transparent !important;
}

.tooltip.right .tooltip-arrow {
    top: 50% !important;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: transparent !important;
}

.tooltip.left .tooltip-arrow {
    top: 50% !important;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: transparent !important;
}

.bs-tooltip-left .arrow::before {
    left: 0;
    top: 50%;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: transparent !important;
}

.tooltip.left {
    padding: 0 5px;
    margin-left: -30px !important;
}

/********/
.menu-title {
    /* max-height: 30px; */
    min-height: 30px;
    color: #0a2864;
    min-width: 350px;
}

/* .group-filters {
    height: 40px;
    width: 100%;
} */

/********/
/* .nav>li>a:focus {
    text-decoration: none;
    background-color: #021f54 !important;
    color: white !important;
} */

/***********pophover*******/

.popover > .arrow {
    border-width: 0px;
}

.bs-popover-right > .arrow {
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
}

.popover.right {
    margin-left: 1px !important;
}

.popover-body {
    min-width: 80px !important;
    margin: 5px;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: transparent;
}

.popover {
    border-radius: 0%;
    z-index: 99999;
}

/*********terms**************/
.cc-color-override-1302255970.cc-window {
    color: rgb(10, 40, 100);
    background-color: rgb(255, 255, 255);
}

.cc-banner.cc-bottom {
    left: 0;
    right: 0;
    bottom: 0;
}

.cc-window.cc-banner {
    align-items: center;
}

.cc-window.cc-banner {
    padding: 1em 1.8em;
    width: 100%;
    flex-direction: row;
}

.cc-bottom {
    bottom: 1em;
}

.cc-revoke,
.cc-window {
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    font-family: Helvetica, Calibri, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5em;
    display: flex;
    flex-wrap: nowrap;
    z-index: 9999;
}

.cc-window {
    opacity: 1;
    transition: opacity 1s ease;
}

.cc-banner .cc-message {
    flex: 1;
}

.cc-compliance {
    display: flex;
    align-items: center;
    align-content: space-between;
}

.cc-banner .cc-btn:last-child {
    min-width: 140px;
}

.cc-color-override-1302255970 .cc-btn {
    color: rgb(255, 255, 255);
    background-color: rgb(10, 40, 100);
    border-color: transparent;
}

.cc-compliance > .cc-btn {
    flex: 1;
}

.cc-btn {
    display: block;
    padding: 0.4em 0.8em;
    font-size: 0.9em;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
    cursor: pointer;
}

.cc-color-override-1302255970 .cc-btn:hover,
.cc-color-override-1302255970 .cc-btn:focus {
    background-color: rgb(157, 195, 51);
    color: white;
}

.cc-color-override-1302255970 .cc-btn {
    color: rgb(255, 255, 255);
    background-color: rgb(10, 40, 100);
    border-color: transparent;
}

.cc-link,
.cc-revoke:hover {
    text-decoration: underline;
}

.cc-compliance > a {
    text-decoration: underline;
}

.linefeed {
    white-space: pre-wrap;
}

/*******/
.filterButton.active .round-button {
    color: var(--ista-sky-blue);
}

.filterButton {
    color: #0a2864;
    cursor: pointer;
}

.filter.visible {
    visibility: visible;
    opacity: 1;
    transition:
        visibility 0s linear 300ms,
        opacity 300ms;
}

.filter.hidden {
    visibility: hidden;
    opacity: 0;
    transition:
        visibility 0s linear 0s,
        opacity 300ms;
}

.button-rounded-right {
    border-radius: 0 0 2rem 0 !important;
}

.button-rounded-left {
    border-radius: 0 0 0 2rem !important;
}

/* MEVODIG-4221-4222 */
.showBar {
    width: 30rem !important;
}

.form-title {
    font-family: 'TheSansE5-ExtraBold';
    font-size: 2.3rem !important;
    color: rgb(0, 0, 60);
}

ui-tag-badge {
    background-color: #e6f2ee;
    color: #007855;
    border-radius: 5px;
    font-family: 'TheSansE5-ExtraBold';
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: center;
    min-width: 80px;
    max-width: 120px;
}

ui-tag-badge.positiveStatus {
    background-color: #f3f8e6;
    color: #374c00;
    min-width: 20px;
    max-width: 20px;
}

.buttonClass {
    min-width: 84px !important;
    border-radius: 0.5rem;
    font-family: 'TheSansE5-SemiLight';
    font-size: small;
    margin-right: 10px !important;
}

.primaryButton,
.primaryButton:focus {
    background-color: #006bff;
    color: #fff;
    border: 1px solid #006bff;
}

.primaryButton:hover {
    background-color: #0044a4;
    color: #fff;
    border: 1px solid #0044a4;
}

.primaryButton:active {
    background-color: #003074;
    color: #fff;
    border: 1px solid #003074;
}

.secondaryButton,
.secondaryButton:focus {
    background-color: #fff;
    color: #006bff;
    border: 1px solid #006bff;
}

.secondaryButton:hover {
    background-color: #deedfd;
    color: #006bff;
    border: 1px solid #006bff;
}

.secondaryButton:active {
    background-color: #b9d7f9;
    color: #006bff;
    border: 1px solid #006bff;
}

.tertiaryButton,
.tertiaryButton:focus {
    background-color: #fff;
    color: #006bff;
    border: 1px solid #fff;
}

.tertiaryButton:hover {
    background-color: #deedfd;
    color: #006bff;
    border: 1px solid #deedfd;
}

.tertiaryButton:active {
    background-color: #b9d7f9;
    color: #006bff;
    border: 1px solid #b9d7f9;
}

.small-icon {
    font-size: 18px !important;
    color: #006bff !important;
}

.button-calendar {
    position: relative;
    top: -2px;
    left: 0px;
    height: 14px !important;
    width: 14px !important;
    display: flex !important;
    justify-content: center;
    font-size: 17px !important;
    overflow: visible !important;
}

.calendar-round-button {
    position: relative;
    top: 2px;
    left: -26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 15px !important;
    width: 25px;
    height: 25px;
    color: #526378;
    background-color: transparent;
    z-index: 1000;
    cursor: pointer;
}

.calendar-round-button:hover {
    background-color: #deedfd;
    color: #0044a4;
}

.calendar-round-button:active {
    background-color: #b9d7f9;
    color: #006bff;
}

.round-button {
    align-self: center;
    margin: 2px;
    padding: 0px;
    border-radius: 16px;
    width: 30px;
    height: 30px;
    color: #526378;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.round-button:hover {
    background-color: #deedfd;
    color: #0044a4;
}

.round-button:active {
    background-color: #b9d7f9;
    color: #006bff;
}

/* Badges */

.to_plan {
    background-color: var(--ista-dark-orange);
}

.planned {
    background-color: #0071ff;
}

.finished {
    background-color: var(--ista-dark-green);
}

.in_progress {
    background-color: #1beb83;
}

.help_us {
    background-color: #e73c06;
}

.not_equipable {
    background-color: #a9b4c0;
}
